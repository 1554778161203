import React, { Component } from "react";

import "./ImageButton.css";

export default class ImageButton extends Component {
  state = {};
  render() {
    return (
      <div className="Image-button">
        <div className="Image-button-image">
          <img
            alt={this.props.buttonTitle + " " + this.props.buttonDescription}
            src={this.props.buttonOverlay}
          />
        </div>
        <div className="Image-button-overlay">
          <img
            alt={this.props.buttonTitle + " " + this.props.buttonDescription}
            src={this.props.buttonImage}
          />
          <div className="Image-button-text">
            <p className="Image-button-title">{this.props.buttonTitle}</p>
            <p className="Image-button-description">
              {this.props.buttonDescription}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
