import React, { Component } from "react";

export default class TextSection extends Component {
  state = {};
  render() {
    return (
      <div>
        <img alt="" src={this.props.image} />
        <p>{this.props.text}</p>
      </div>
    );
  }
}
