import {
  activeAktiva,
  activeAlva,
  activeBioeffect,
  activeAOR,
  activeGBW,
  activeMRM,
  activeNetgiro,
  activeNetgiroRedesign,
  //activeOktave,
  activeResume,
  activeVegachef,
  activeVergo,
  hoverAktiva,
  hoverAlva,
  hoverAOR,
  hoverBioeffect,
  hoverGBW,
  hoverMRM,
  hoverNetgiro,
  hoverNetgiroRedesign,
  //hoverOktave,
  hoverResume,
  hoverVegachef,
  hoverVergo,
  //--------
  aktivaHeader,
  aktivaDescription,
  aktivaColors,
  aktivaFlowchart,
  aktivaTypography,
  aktivaResults,
  alvaHeader,
  alvaDescription,
  alvaColors,
  alvaSystem,
  alvaFooter,
  aorHeader,
  aorDescription,
  aorLogoCreation,
  aorResults,
  aorFooter,
  bioeffectHeader,
  mrmHeader,
  mrmDescription,
  mrmLogoCreation,
  mrmResults,
  mrmBreak,
  mrmMaterial,
  netgiroHeader,
  netgiroDescription,
  netgiroColors,
  netgiroTypography,
  netgiroScreens,
  netgiroPosSystem,
  netgiroRedesignHeader,
  netgiroRedesignDescription,
  netgiroRedesignColors,
  netgiroRedesignBranding,
  netgiroRedesignPrototype,
  netgiroRedesignSketches,
  netgiroRedesignResults,
  resumeHeader,
  resume,
  vegachefHeader,
  vergoHeader,
  vergoDescription,
  vergoResults
} from "../assets";

const PortfolioAPI = {
  portfolio: [
    {
      number: 1,
      ref: "resume",
      title: "An Overview",
      description: "Resume",
      image: activeResume,
      overlay: hoverResume,
      contentA: resume,
      contentB: resumeHeader
    },
    {
      number: 2,
      ref: "bioeffect",
      title: "UI/UX Design & Development",
      description: "BIOEFFECT",
      image: activeBioeffect,
      overlay: hoverBioeffect,
      contentA: bioeffectHeader
    },
    {
      number: 3,
      ref: "vegachef",
      title: "Product Development",
      description: "Vegachef",
      image: activeVegachef,
      overlay: hoverVegachef,
      contentA: vegachefHeader
    },
    {
      number: 4,
      ref: "netgiro",
      title: "Product Design",
      description: "Netgiro",
      image: activeNetgiro,
      overlay: hoverNetgiro,
      contentA: netgiroHeader,
      contentB: netgiroDescription,
      contentC: netgiroColors,
      contentD: netgiroTypography,
      contentE: netgiroScreens,
      contentF: netgiroPosSystem
    },
    {
      number: 5,
      ref: "netgiro-redesign",
      title: "Product Design Case Study",
      description: "Netgiro Redesign",
      image: activeNetgiroRedesign,
      overlay: hoverNetgiroRedesign,
      contentA: netgiroRedesignHeader,
      contentB: netgiroRedesignDescription,
      contentC: netgiroRedesignColors,
      contentD: netgiroRedesignBranding,
      contentE: netgiroRedesignSketches,
      contentF: netgiroRedesignPrototype,
      contentG: netgiroRedesignResults
    },
    {
      number: 6,
      ref: "aktiva",
      title: "Product Design & Development",
      description: "Aktiva",
      image: activeAktiva,
      overlay: hoverAktiva,
      contentA: aktivaHeader,
      contentB: aktivaDescription,
      contentC: aktivaFlowchart,
      contentD: aktivaColors,
      contentE: aktivaTypography,
      contentF: aktivaResults
    },
    {
      number: 7,
      ref: "alva",
      title: "Brand Identity",
      description: "ALVA Holdings",
      image: activeAlva,
      overlay: hoverAlva,
      contentA: alvaHeader,
      contentB: alvaDescription,
      contentC: alvaColors,
      contentD: alvaSystem,
      contentE: alvaFooter
    },
    {
      number: 8,
      ref: "vergo",
      title: "Product Design Design",
      description: "Vergo",
      image: activeVergo,
      overlay: hoverVergo,
      contentA: vergoHeader,
      contentB: vergoDescription,
      contentC: vergoResults
    },
    {
      number: 9,
      ref: "god-is-a-black-woman-book",
      title: "Illustrations & Storytelling",
      description: "God is a Black Woman",
      image: activeGBW,
      overlay: hoverGBW,
      contentA: ""
    },
    {
      number: 10,
      ref: "aor",
      title: "Brand Identity",
      description: "Axe Order",
      image: activeAOR,
      overlay: hoverAOR,
      contentA: aorHeader,
      contentB: aorDescription,
      contentC: aorLogoCreation,
      contentD: aorResults,
      contentE: aorFooter
    },
    {
      number: 11,
      ref: "mrm",
      title: "Branding & Music Production",
      description: "Movimento Rock",
      image: activeMRM,
      overlay: hoverMRM,
      contentA: mrmHeader,
      contentB: mrmDescription,
      contentC: mrmLogoCreation,
      contentD: mrmResults,
      contentE: mrmBreak,
      contentF: mrmMaterial
    }
  ],
  all: function() {
    return this.portfolio;
  },
  get: function(id) {
    const isPortfolio = p => p.number === id;
    return this.portfolio.find(isPortfolio);
  }
};

export default PortfolioAPI;
