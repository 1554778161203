import React, { Component } from "react";
import "./IconButton.css";

export default class IconButton extends Component {
  state = {};
  render() {
    return (
      <a
        className="Icon-button"
        href={this.props.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="" src={this.props.image} />
      </a>
    );
  }
}
