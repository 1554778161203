import React, { Component } from "react";
import "./Button.css";

export default class Button extends Component {
  state = {};
  render() {
    return (
      <a
        className="button"
        href={this.props.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>{this.props.text}</p>
      </a>
    );
  }
}
