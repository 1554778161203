import React, { Component } from "react";

export default class Contact extends Component {
  state = {};
  render() {
    return (
      <div className="Contact-page">
        <p>Contact Form</p>
      </div>
    );
  }
}
