import React, { Component } from "react";
// import { BrowserRouter as Router, NavLink, Route } from "react-router-dom";

import { Content, Header, Footer } from "../components";

import "./styles.css";

class Main extends Component {
  render() {
    return (
      <div className="Main-Page">
        <Header />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default Main;
