import React, { Component } from "react";
import { Button } from "../index";
import "./Footer.css";

export default class Footer extends Component {
  state = {};
  getYear() {
    return new Date().getFullYear();
  }
  render() {
    return (
      <div className="Footer">
        <Button link="mailto:contact@yarapolana.com" text="Get in Touch" />
        <p className="Footer-rights">
          ©{this.getYear()} Yara Polana. Made with &hearts; using React
        </p>
      </div>
    );
  }
}
