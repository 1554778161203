import React from "react";
import PortfolioAPI from "../../scripts/api";
import { Image, PortfolioButton } from "../../components";
import { Link, Redirect } from "react-router-dom";

import "./DisplayPortfolio.css";

const DisplayPortfolioPage = props => {
  const portfolio = PortfolioAPI.get(parseInt(props.match.params.number, 10));

  if (!portfolio) {
    return (
      <div>
        <Link to="/work">Return to Portfolio</Link>
        <div>
          <h2>Sorry, this page is not available.</h2>
          <p>Please select another one.</p>
        </div>
      </div>
    );
  }
  if (portfolio.ref === "god-is-a-black-woman-book") {
    return <Redirect to="/book" />;
  }
  return (
    <div className="Display-portfolio-page">
      <p>
        <Link to="/work">Return to Portfolio</Link>
      </p>
      <PortfolioButton
        buttonImage={portfolio.image}
        buttonTitle={portfolio.title}
        buttonDescription={portfolio.description}
        buttonOverlay={portfolio.overlay}
      />
      <Image source={portfolio.contentA} />
      <Image source={portfolio.contentB} />
      <Image source={portfolio.contentC} />
      <Image source={portfolio.contentD} />
      <Image source={portfolio.contentE} />
      <Image source={portfolio.contentF} />
      <Image source={portfolio.contentG} />
    </div>
  );
};
export default DisplayPortfolioPage;
