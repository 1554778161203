import React, { Component } from "react";
import Lazyload from "react-lazyload";
import "./Image.css";

export default class Image extends Component {
  state = {};
  render() {
    return (
      <Lazyload>
        <div className="Portfolio-image">
          <img alt="" src={this.props.source} />
        </div>
      </Lazyload>
    );
  }
}
