import React, { Component } from "react";
import { logo, avatar } from "../../assets";
import "./Avatar.css";

export default class Avatar extends Component {
  state = {};
  render() {
    return (
      <div className="Avatar-head">
        <img src={avatar} className="Avatar" alt="avatar" />
        <img src={logo} className="Avatar-logo" alt="logo" />
      </div>
    );
  }
}
