import React from "react";
import { Switch, Route } from "react-router-dom";

import { DisplayPortfolioList, DisplayPortfolioPage } from "../components";

const Work = () => (
  <Switch>
    <Route exact path="/work" component={DisplayPortfolioList} />
    <Route path="/work/:number" component={DisplayPortfolioPage} />
  </Switch>
);

export default Work;
