import React, { Component } from "react";
import "./ImageSection.css";

export default class ImageSection extends Component {
  state = {};
  render() {
    return (
      <div className="Content-section">
        <div className="Content-left">
          <img className="About-image" alt="" src={this.props.source} />
        </div>
        <div className="Content-right">
          <h3>{this.props.title}</h3>
          <p>{this.props.text}</p>
        </div>
      </div>
    );
  }
}
