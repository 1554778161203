import React, { Component } from "react";
import "./PortfolioButton.css";
export default class PortfolioButton extends Component {
  render() {
    return (
      <div className="Portfolio-button">
        <div className="Portfolio-button-image">
          <img
            alt={this.props.buttonTitle + " " + this.props.buttonDescription}
            src={this.props.buttonOverlay}
          />
        </div>
        <div className="Portfolio-button-overlay">
          <img
            alt={this.props.buttonTitle + " " + this.props.buttonDescription}
            src={this.props.buttonImage}
          />
          <div className="Portfolio-button-text">
            <p className="Portfolio-button-title">{this.props.buttonTitle}</p>
            <p className="Portfolio-button-description">
              {this.props.buttonDescription}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
