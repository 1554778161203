import React, { Component } from "react";
import { Image, Title } from "../components";
import { Link } from "react-router-dom";
import { aboutMe, aboutGuitar } from "../assets";

export default class About extends Component {
  state = {};
  render() {
    return (
      <div className="About-page">
        <div className="Section">
          <div className="Section-left">
            <Image source={aboutMe} />
          </div>
          <div className="Section-right">
            <Title
              title="Hi, I am thrilled that you came across my page, let me introduce
              myself:"
            />

            <p>
              My name is Yara, a local of 101 Reykjavík, Iceland, and a humble
              multi-faceted, multi-talented artist and tech-nerd. You may be
              asking <i>multi what now?</i>, so long story short, when I was
              little I wanted to do a lot of things, so I decided to go to
              University to study a lot of things, because I believed that
              knowledge is power. So I learned Computer Engineering, Multimedia
              Techniques, Multimedia Communication and finally Visual
              Communication.
            </p>

            <p>
              Studying these subjects allowed me to learn about Web &amp; Mobile
              Development, Product or UI/UX Design, Photography and Video
              Production. With these skills at hand and over 13 years in the
              world field I managed to become Head of Design of Netgiro, Head of
              Design of Alva Holdings, Head of Design of Moberg and Lead
              Developer of BIOEFFECT.
              <p />
              You can see more of these accomplishments on my{" "}
              <Link to="/work/1">Resume</Link>.
            </p>
            <p />
          </div>
        </div>
        <div className="Section">
          <div className="Section-right">
            <Title title="We all have to unwind, and this is what I do:" />
            <p>
              Besides creating magic in the professional world, I create magic
              by expressing myself on stage with my guitar, voice and drumkit,
              at home with my loved one &hearts;, and online via social media,
              having around +3.400 followers (not much but hey we all start
              somewhere.)
            </p>
            <p>
              I know you came here for a reason, but if that reason is not
              fullfilled please get in touch with me. I'd love to talk to you
              either just a chat or to build something new. Dont forget to
              follow me on{" "}
              <a
                href="https://www.instagram.com/monace_thequeen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                social media
              </a>{" "}
              if you are interested in the journey of a humble 101-ist =)
              <p />
            </p>
          </div>
          <div className="Section-left">
            <Image source={aboutGuitar} />
          </div>
        </div>
      </div>
    );
  }
}
