import React, { Component } from "react";
import { Greeting, IconSection } from "../components";

import "./styles.css";

export default class Home extends Component {
  state = {};
  render() {
    return (
      <div className="Home-page">
        <Greeting />
        <IconSection />
      </div>
    );
  }
}
