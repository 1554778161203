import React, { Component } from "react";
import { IconButton } from "../index";
import { iconGit, iconEmail, iconLinkedin } from "../../assets";
import "./IconSection.css";

export default class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="Icon-section">
        <IconButton link="https://github.com/yarapolana" image={iconGit} />
        <IconButton link="mailto:contact@yarapolana.com" image={iconEmail} />
        <IconButton
          link="https://www.linkedin.com/in/yarapolana/"
          image={iconLinkedin}
        />
      </div>
    );
  }
}
