import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "./Content.css";
import About from "../../pages/About";
import Book from "../../pages/Book";
import Home from "../../pages/Home";
import Work from "../../pages/Work";
import Contact from "../../pages/Contact";

import Lazyload from "react-lazyload";

export default class Content extends Component {
  render() {
    function Status({ code, children }) {
      return (
        <Route
          render={({ staticContext }) => {
            if (staticContext) staticContext.status = code;
            return children;
          }}
        />
      );
    }

    function NotFound() {
      return (
        <Status code={404}>
          <div>
            <h2>Sorry, this page is not available.</h2>
            <p>Please select another one.</p>
          </div>
        </Status>
      );
    }

    return (
      <main className="content">
        <Lazyload height={200}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/book" component={Book} />
            <Route path="/work" component={Work} />
            <Route path="/contact" component={Contact} />
            <Route path="*">
              <Redirect to="/" />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Lazyload>
      </main>
    );
  }
}
