import React, { Component } from "react";
import { Image } from "../../components";
import { Link } from "react-router-dom";

import {
  book1,
  book2,
  book3,
  book4,
  book5,
  book6,
  book7,
  book8,
  book9,
  book10,
  book11,
  book12,
  book13,
  book14,
  book15,
  book16,
  bookCover,
  bookAfrica,
  bookMozambique
} from "../../assets";

import "./DisplayBookPage.css";

export default class DisplayBookPage extends Component {
  state = {};
  render() {
    return (
      <div>
        <Link to="/">
          <div className="Book-page-link">
            <p>&larr;</p>
          </div>
        </Link>

        <Image source={book1} />

        <div className="Book-page-Text">
          <p />
          <h1>God is a Black Woman</h1>
          <p>
            Is a fictional account of the creation of the universe, and
            qualifies as a spiritual and lyrical version of “A Biography of The
            Universe”. Inspired by the Macuan tribe from the north of
            Mozambique, that is known to be a Matrilineal society thus slowly
            falling into southern Patriarchal culture, the story brings forward
            their traditional masks, culture, dance and beliefs in hopes of
            enlighten the world on its existence.
          </p>
        </div>

        <div className="Section">
          <div className="Section-right">
            <h1>Why write this book?</h1>
            <p>
              Yara hopes to place herself squarely with those who are committed
              to peace, in every sense of that word. This includes peace between
              the genders, the races, the Faiths, as well as our painful common
              history. She identifies strongly with the many efforts to
              resurrect The Sacred Feminine, so its long overdue merging with a
              largely unguided and love-starved Sacred Masculine, can be brought
              into common awareness.
            </p>
            <p>
              Yara believes that many African tribes illustrate the strength
              that Community, in its best sense, gives to its individual
              members. Some also illustrate the ways in which communities are
              led from their path, when fear rules. Knowing our spiritual
              stories and histories can prevent repetition of our most
              catastrophic mistakes, and that may well happen if we sincerely
              so, wish.
            </p>
          </div>
          <div className="Section-left">
            <Image source={bookCover} />
          </div>
        </div>

        <Image source={book2} />
        <Image source={book3} />

        <div className="Section">
          <div className="Section-right">
            <h1>History</h1>
            <p>
              Yara Polana, a native of Mozambique, has written, designed and
              illustrated a poetic and beautiful story, based on her experience
              of being brought up in a country that is truly multi-ethnic and
              multi-tribal community of 24 million people. This story is
              inspired by a matriarchal tribe, The Macua, and blends in
              background and myths of other tribes, creating a fable that shares
              much with well known Creation Myths of many regions and epochs of
              the world.
            </p>
            <p>
              Mozambique, a former colony of Portugal during 500 years, is
              located on the east coast of Africa, neighboring South Africa to
              the south, its latitude parallel to that of the island of
              Madagascar to the east, in the southern hemisphere. The author is
              grateful for having been brought up in a country that, despite its
              many struggles, is a remarkably integrated, ethnically, having no
              “china-towns” or “little-Italia” in its major urban areas.
            </p>
            <p>
              The Macua that live on tribal lands in the northern part of
              Mozambique reaching into southern Tanzania, have preserved a
              matriarchal form of community, based on their believe that the
              earth is a female deity, and the mother primordial.
            </p>
          </div>
          <div className="Section-left">
            <Image source={bookAfrica} />
          </div>
        </div>

        <Image source={book4} />
        <Image source={book5} />

        <div className="Section">
          <div className="Section-right">
            <h1>Background</h1>
            <p>
              Yara is a multifaceted and talented artist. She is Head of Design
              of Moberg ehf. with over 10 years of experience in the fields of
              Design, Creative Direction and Front-end Programming with an eye
              for accuracy. Her other expressive side involves being a musician,
              music producer and video producer.
            </p>
            <p>
              Born in the capital of her country, Maputo, to parents of two
              different tribes resulting in Portuguese being the family
              language. She remembers in primary school, how she and her
              classmates were always encouraged to draw the outline of
              Mozambique upside down, result ing in a likeness of a woman in a
              traditional dress washing clothes, and she wonders in this
              practice was accidental or truly based upon the sense that the
              lands of her country were of a feminine nature. She has always
              been fascinated by both what unites people, and separates them.
            </p>
          </div>
          <div className="Section-left">
            <Image source={bookMozambique} />
          </div>
        </div>

        <Image source={book6} />
        <Image source={book7} />
        <Image source={book8} />

        <div className="Section">
          <div className="Section-right">
            <h1>Review</h1>
            <p>
              <i>
                "I must say with great admiration that “God is a Black Woman”
                was beautifully written in calibre. As Africans we have so many
                stories to tell but we don’t document them this causing the
                stories to be lost with the people who know it. So it always
                extraordinary having africans publishing African books. African
                literature that preserves history and understanding of how our
                people think and what they believe in.
              </i>
            </p>
            <p>
              <i>
                What I found an interesting twist was that “God is a Black
                Woman” yes it was refreshing to know that it didn’t confine to
                the usual. That it celebrates women being leaders. Women who
                were strength to build a universe and that strength doesn’t
                manifest in muscle power but in the ability to appreciate what
                we have and nurture it..."
              </i>
            </p>
          </div>
        </div>

        <Image source={book9} />
        <Image source={book10} />
        <Image source={book11} />
        <div className="Section">
          <div className="Section-right">
            <p>
              <i>
                "... The way the book highlights the problems of the human race
                made it more real to me and even through it is written in the
                very old past type of setting . It is still applicable to the
                present how we are greedy and how we will go about getting power
                in all forms possible, this is clearly a first world problem.
                “War was symbolised as a bowl of blood as it represents the only
                true Harvest of War” this portion was moving and powerful it
                just grabbed me..."
              </i>
            </p>
          </div>
        </div>
        <Image source={book12} />
        <Image source={book13} />
        <div className="Section">
          <div className="Section-right">
            <p>
              <i>
                "... True leaders do not impose their power even though they
                know they can, they do not dictate but give its people free will
                and they understand that we have our faults and believe that we
                will come right and love will conquer at the end. This was
                appealing encompassed in the ending of the book.
              </i>
            </p>
            <p>
              <i>
                Born in the capital of her country, Maputo, to parents of two
                different tribes resulting in Portuguese being the family
                language. She remembers in primary school, how she and her
                classmates were always encouraged to draw the outline of
                Mozambique upside down, result ing in a likeness of a woman in a
                traditional dress washing clothes, and she wonders in this
                practice was accidental or truly based upon the sense that the
                lands of her country were of a feminine nature. She has always
                been fascinated by both what unites people, and separates them.
                I highly recommend reading this book."
              </i>
              {"  "}
              by Liandra Basiane.
            </p>
          </div>
        </div>
        <Image source={book14} />
        <Image source={book15} />
        <Image source={book16} />
        <div className="Book-page-convert">
          <a
            href="https://www.amazon.com/God-Black-Woman-Yara-Polana/dp/1511905018"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>BUY on Amazon</p>
          </a>
        </div>
      </div>
    );
  }
}
