import React, { Component } from "react";
import "./Greeting.css";

export default class Greeting extends Component {
  state = {};
  render() {
    return (
      <div className="Greetings">
        <p>Hæ, I'm</p>
        <h1>Yara Polana</h1>
        <h3>Senior Designer &amp; Developer </h3>
      </div>
    );
  }
}
