import React, { Component } from "react";
import { DisplayBookPage } from "../components";

export default class Book extends Component {
  state = {};
  render() {
    return (
      <div className="Book-page">
        <DisplayBookPage />
      </div>
    );
  }
}
