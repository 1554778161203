import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Avatar } from "../index";
import "./Header.css";

export default class Header extends Component {
  render() {
    return (
      <div className="Header">
        <nav>
          <ul>
            <li className="Header-nav">
              <NavLink to="/about">About</NavLink>
            </li>
            <li className="Header-nav">
              <NavLink to="/work">Work</NavLink>
            </li>
            <li className="Header-avatar">
              <NavLink exact to="/">
                <Avatar />
              </NavLink>
            </li>
            <li className="Header-nav">
              <NavLink to="/book">Book</NavLink>
            </li>
            <li className="Header-nav">
              <a href="mailto:contact@yarapolana.com">Contact</a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
