import React, { Component } from "react";
import { Link } from "react-router-dom";
import Lazyload from "react-lazyload";

import { PortfolioButton } from "..";
import PortfolioAPI from "../../scripts/api";

import "./DisplayPortfolio.css";

export default class DisplayPortfolioList extends Component {
  state = {};
  render() {
    return (
      <div className="Display-portfolio-list">
        <ul>
          {PortfolioAPI.all().map(p => (
            <li>
              <Lazyload height={400}>
                <Link to={`/work/${p.number}`}>
                  <PortfolioButton
                    buttonImage={p.image}
                    buttonTitle={p.title}
                    buttonDescription={p.description}
                    buttonOverlay={p.overlay}
                  />
                </Link>
              </Lazyload>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
